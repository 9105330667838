<template>
  <v-footer
    id="home-footer"
    color="#1a237e"
    dark
    min-height="60"
  >
    <v-container fluid>
      <v-row>
        <v-col
          class="d-flex flex-wrap justify-md-start justify-center justify-md-none "
          cols="12"
          md="6"
        >
          <a href="https://www.computacenter.com" class="white--text">2021 &copy; Computacenter AG & Co oHG</a>
          <span >&nbsp;/ Version: {{version}}</span>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <div class="text-center text-md-right d-flex flex-wrap justify-md-end justify-center justify-md-none">
            <template v-for="(s, i) in pages">
              <a
                :key="s"
                class="white--text pa-1 pa-md-0"
                :href="'/#/' + s"
                v-text="s"
              />

              <v-responsive
                v-if="i < pages.length - 1"
                :key="`divider-${s}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    // This component describes the footer component, shown below every content page.

    name: 'HomeFooter',

    data: () => ({
      pages: [
        'About'
      ],
    }),

    computed:{
      version(){
        return process.env.VUE_APP_VERSION
      }
    }
  }
</script>

<style lang="scss">
  home-footer {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
    
  home-footer a {
    text-decoration: none;
  }
</style>
