var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      attrs: {
        id: "home-footer",
        color: "#1a237e",
        dark: "",
        "min-height": "60",
      },
    },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex flex-wrap justify-md-start justify-center justify-md-none ",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "white--text",
                      attrs: { href: "https://www.computacenter.com" },
                    },
                    [_vm._v("2021 © Computacenter AG & Co oHG")]
                  ),
                  _c("span", [_vm._v(" / Version: " + _vm._s(_vm.version))]),
                ]
              ),
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-center text-md-right d-flex flex-wrap justify-md-end justify-center justify-md-none",
                  },
                  [
                    _vm._l(_vm.pages, function (s, i) {
                      return [
                        _c("a", {
                          key: s,
                          staticClass: "white--text pa-1 pa-md-0",
                          attrs: { href: "/#/" + s },
                          domProps: { textContent: _vm._s(s) },
                        }),
                        i < _vm.pages.length - 1
                          ? _c(
                              "v-responsive",
                              {
                                key: "divider-" + s,
                                staticClass: "mx-4 shrink hidden-sm-and-down",
                                attrs: { "max-height": "24" },
                              },
                              [_c("v-divider", { attrs: { vertical: "" } })],
                              1
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }